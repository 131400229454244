import React, { useState, useCallback } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import "./UploadComponent.css";
import { toast } from "react-toastify";

const UploadComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleRemoveFile = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 1024 * 1024 * 200,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const apiUrl =
      process.env.REACT_APP_API_URL || "https://ftpapi.mijnafvalwijzer.nl";

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await fetch(`${apiUrl}/upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const result = await response.json();

        if (response.status === 401) {
          toast.error(`Unauthorized: ${result.message}`);
          return;
        } else if (response.status === 403) {
          toast.error(`Forbidden: ${result.message}`);
          return;
        } else if (response.status === 500) {
          toast.error(`Server Error: ${result.message}`);
          return;
        } else {
          toast.error(`HTTP Error: ${response.status} ${result.message}`);
          return;
        }
      }

      const result = await response.json();
      toast.success(`Success: ${result.message}`);
    } catch (error) {
      toast.error(`Error: An unknown error occurred`);
    }
  };

  return (
    <Container as="article" className="upload-container">
      <Row className="justify-content-md-center">
        <Col md="auto">
          <Form onSubmit={handleSubmit} className="upload-form">
            <Form.Group controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <div
              {...getRootProps()}
              className={`dropzone ${isDragActive ? "active" : ""}`}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag & drop some files here, or click to select files</p>
              )}
            </div>

            {/* Display selected files with remove option */}
            <ul className="file-list">
              {files.map((file, index) => (
                <li key={index}>
                  {file.name}
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => handleRemoveFile(file.name)}
                    className="remove-file-btn"
                  >
                    Remove
                  </Button>
                </li>
              ))}
            </ul>

            {/* Placeholder for reCAPTCHA */}
            {/* <div className="recaptcha-placeholder">reCAPTCHA will go here</div> */}

            <Button variant="primary" className="upload-btn" type="submit">
              Upload
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UploadComponent;
