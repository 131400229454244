import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col } from "react-bootstrap";
import UploadComponent from "./upload/UploadComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import afvalLogo from "./assets/images/afvalwijzer-logo.svg";
import sideBarImg from "./assets/images/sidebar-img.svg";

const App = () => {
  return (
    <div>
      <ToastContainer />
      <Container fluid className="full-height bg-white" as="main">
        <Row className="header-bar px-3 py-4 bg-white">
          <Col md={12} className="d-flex justify-content-center">
            <img src={afvalLogo} alt="Afvalwijzer Logo" className="logo"/>
          </Col>
        </Row>
        <Row>
          <Col
            xs={0}
            sm={5}
            md={6}
            className="left-container d-flex justify-content-center align-items-center"
          >
            <div className="d-flex img-container justify-content-center align-items-center">
              <img
                src={sideBarImg}
                alt="Display"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col xs={12} sm={7} md={6} className="my-auto right-container d-flex justify-content-center align-items-center">
            <div className="form-container">
              <UploadComponent />
            </div>
          </Col>
        </Row>
        <Row className="footer">
          <Col md={12}>
            © {new Date().getFullYear()} All rights reserved.
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default App;
